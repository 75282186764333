import React from "react"
import Layout from "../components/layout"
import Guide from "../components/guide"

const GuideTemplate = ({ pageContext }) => (
  <Layout site={pageContext.site}>
    <Guide/>
  </Layout>
)

export default GuideTemplate