import React from "react"
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import WindowsLogo from "./logo-windows"
import MacOSLogo from "./logo-macos"
import LinuxLogo from "./logo-linux"


const getParameters = (location, key, default_value) => {
    const parsed = queryString.parse(location.search);
    if(parsed[key]) {
        return parsed[key];
    } else {
        return default_value;
    }
}

const create_album_env_bash = () => `conda env create -f https://gitlab.com/album-app/album/-/raw/main/album.yml
conda activate album
`

const add_catalog_bash = (url) => `album add-catalog ${url}
`

const launch_solution_doi_bash = solutionDoi => `album install ${solutionDoi}
album info ${solutionDoi}
`

const install_solution_bash = (solutionGroup, solutionName, solutionVersion) => `album install ${solutionGroup}:${solutionName}:${solutionVersion}
`
const info_solution_bash = (solutionGroup, solutionName, solutionVersion) => `album info ${solutionGroup}:${solutionName}:${solutionVersion}
`
const launch_solution_bash = (solutionGroup, solutionName, solutionVersion) => `album run ${solutionGroup}:${solutionName}:${solutionVersion}
`

const getInputHeader = (catalogUrl, catalogName, solutionGroup, solutionName, solutionVersion, doi) => {
    return (<form id="form" class='guide'>
        <div class='guide-inputs'>
            <div>
                <div className="form-title">Catalog</div>
                <div>
                    <label>location:</label>
                    <input type='text' class="input-catalog-url" value={catalogUrl}/>
                </div>
                <div>
                    <label>name:</label>
                    <input type='text' class="input-catalog-name" value={catalogName}/>
                </div>
            </div>
            <div>
                <div className="form-title">Solution</div>
                <div>
                    <label>group:</label>
                    <input type='text' class="input-solution-group" value={solutionGroup}/>
                </div>
                <div>
                    <label>name:</label>
                    <input type='text' class="input-solution-name" value={solutionName}/>
                </div>
                <div>
                    <label>version:</label>
                    <input type='text' class="input-solution-version" value={solutionVersion}/>
                </div>
            </div>
        </div>
     </form>)
}
const getGuiGuide = (catalogUrl, catalogName, solutionGroup, solutionName, solutionVersion, doi) => {
    return (<>
              <div className="doc-box doc-gui">
                <div className="doc-box-title">.. via graphical user interface</div>
                <div className="doc-box-content">
                  If you haven't already, install the album app.
                  <div className="download-items">
                      <a href="https://gitlab.com/album-app/album-app/-/jobs/artifacts/main/raw/build/image.zip?job=ubuntu_deploy" className="os-specific-download">
                      <LinuxLogo/><div>Download ZIP by clicking here, unzip it and execute <code>image/bin/album</code>.</div></a>
                      <a href="https://gitlab.com/album-app/album-app/-/jobs/artifacts/main/raw/build/image.zip?job=windows_deploy" className="os-specific-download">
                      <WindowsLogo/><div>Download ZIP by clicking here, unzip it and execute <code>image/bin/album.bat</code>.</div></a>
                      <a href="https://gitlab.com/album-app/album-app/-/jobs/artifacts/main/raw/build/image.zip?job=macos_deploy" className="os-specific-download">
                      <MacOSLogo/><div>Download ZIP by clicking here, unzip it and execute <code>image/bin/album</code>.</div></a>
                  </div>
                </div>
                <div className="doc-box-content">
                  Launch the album app and, if you are launching it for the first time, follow the instructions for installing album on your machine.
                </div>
                {doi != null? <div className="doc-box-content">
                  To run only one particular solution, use the <span className="gui-button">Install and run from DOI</span> button and insert this: <code className="language-bash">{doi}</code>.
                </div>: ""}
                <div className="doc-box-content">
                  You can add this catalog to your collection by pressing the <span className="gui-button">Add catalog</span> button and pasting this URL into the popup window:<br/><br/>
                  <code className="language-bash">{catalogUrl}</code>
                  <br/><br/>Load your collection by clicking <span className="gui-button">Load collection</span> and select the catalog named <span className="gui-button">{catalogName}</span>. On the right, scroll to <span className="gui-button">{solutionGroup}:{solutionName}</span>.
                  </div>
              </div></>)
}

const Guide = ({content}) => {

    const catalogUrl = getParameters(useLocation(), "catalog_url", "CATALOG_URL")
    const catalogName = getParameters(useLocation(), "catalog_name", "CATALOG_NAME")
    const solutionGroup = getParameters(useLocation(), "group", "GROUP")
    const solutionName = getParameters(useLocation(), "name", "NAME")
    const solutionVersion = getParameters(useLocation(), "version", "VERSION")
    const doi = getParameters(useLocation(), "doi", "DOI_UNKNOWN")

    return  (
        <>
            <p>This is a guide for how to deal with one specific solution, living in a public Album catalog.</p>

            {
                getInputHeader(catalogUrl, catalogName, solutionGroup, solutionName, solutionVersion, doi)
            }
            {
                //getGuiGuide(catalogUrl, catalogName, solutionGroup, solutionName, solutionVersion, doi)
            }
            <h2>How to run {solutionGroup}:{solutionName}:{solutionVersion} via commandline</h2>
            <p>Please follow our <a href="https://docs.album.solutions/en/latest/installation-instructions.html">commandline guide</a> for installation instructions.</p>
            <p>Make sure your Album environment is activated:</p>
            <pre><code>conda activate album</code></pre>
            Now you can add this catalog to your collection (in case you haven't done that already):
            <pre><code className="language-terminal">{add_catalog_bash(catalogUrl)}</code></pre>
            Install the solution by name:
            <pre><code className="language-terminal">{install_solution_bash(solutionGroup, solutionName, solutionVersion)}</code></pre>
            You can run the solution by simply typing this command:
            <pre><code className="language-terminal">{launch_solution_bash(solutionGroup, solutionName, solutionVersion)}</code></pre>
            This call might fail because the solution has required input parameters - you can list these input parameters as well as print the solution metadata like the credits for the tools used in this solution:
            <pre><code className="language-terminal">{info_solution_bash(solutionGroup, solutionName, solutionVersion)}</code></pre>
    </>);

}


export default Guide
